<template>
  <data-tables
    :data="data"
    :pagination-props="paginationDef"
    :table-props="tableProps"
    :show-action-bar="false"
    :filters="[searchDef]"
    style="width: 100%"
    class="collection-table"
    v-loading="loading"
  >
    <el-row class="table-header" slot="tool">
      <el-col class="table-header-name">
        <span>Collections</span>
      </el-col>
      <el-col class="table-header-container">
        <div style="flex: 1">
          <el-input
            v-model="searchDef.value"
            placeholder="Search By Name..."
            size="small"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div style="margin-left: 1rem; white-space: nowrap">
          <el-button type="info" @click="openSortCollectionModal" size="small">
            Reorder
          </el-button>
          <el-button
            type="primary"
            @click="openCreateCollectionModal"
            size="small"
          >
            New
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-table-column
      prop="order"
      label="Order"
      sortable="custom"
      width="80"
    ></el-table-column>

    <el-table-column
      prop="name"
      label="Name"
      sortable="custom"
      min-width="150"
      show-overflow-tooltip
    ></el-table-column>

    <el-table-column prop="default" label="Default" width="100" align="center">
      <template slot-scope="scope">
        <font-awesome-icon
          icon="check"
          v-if="scope.row.default"
          fixed-width
        ></font-awesome-icon>
        <font-awesome-icon
          icon="times"
          style="color: #c0c4cc"
          v-else
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column prop="mime_types" label="Files" width="100" align="center">
      <template slot-scope="scope">
        <font-awesome-icon
          icon="check"
          v-if="scope.row.mime_types !== null"
          fixed-width
        ></font-awesome-icon>
        <font-awesome-icon
          icon="times"
          style="color: #c0c4cc"
          v-else
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column prop="linkable" label="Links" width="100" align="center">
      <template slot-scope="scope">
        <font-awesome-icon
          icon="check"
          v-if="scope.row.linkable"
          fixed-width
        ></font-awesome-icon>
        <font-awesome-icon
          icon="times"
          style="color: #c0c4cc"
          v-else
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column
      prop="orderable"
      label="Orderable"
      width="100"
      align="center"
    >
      <template slot-scope="scope">
        <font-awesome-icon
          icon="check"
          v-if="scope.row.orderable"
          fixed-width
        ></font-awesome-icon>
        <font-awesome-icon
          icon="times"
          style="color: #c0c4cc"
          v-else
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column
      prop="downloadable"
      label="Downloadable"
      width="100"
      align="center"
    >
      <template slot-scope="scope">
        <font-awesome-icon
          icon="check"
          v-if="scope.row.downloadable"
          fixed-width
        ></font-awesome-icon>
        <font-awesome-icon
          icon="times"
          style="color: #c0c4cc"
          v-else
          fixed-width
        ></font-awesome-icon>
      </template>
    </el-table-column>

    <el-table-column
      prop="created_at"
      label="Created"
      sortable="custom"
      width="200"
    >
      <template slot-scope="scope">
        <span>
          {{ dateTimeFormat(scope.row.created_at, "MM/DD/YYYY") }} by
          {{ scope.row.created_by.name }}
        </span>
      </template>
    </el-table-column>

    <el-table-column label="" width="60" align="center">
      <template slot-scope="scope">
        <el-dropdown
          trigger="click"
          @command="collectionDropdown($event, scope.row)"
        >
          <span class="el-dropdown-link">
            <el-button type="text" style="padding: 0" size="small">
              <font-awesome-icon
                icon="ellipsis-v"
                fixed-width
              ></font-awesome-icon>
            </el-button>
          </span>
          <el-dropdown-menu class="dropdown-left-adjust" slot="dropdown">
            <el-dropdown-item command="edit">
              <font-awesome-icon
                icon="pencil-alt"
                fixed-width
              ></font-awesome-icon>
              Edit
            </el-dropdown-item>
            <el-dropdown-item v-if="!scope.row.default" command="delete">
              <font-awesome-icon
                icon="trash-alt"
                fixed-width
              ></font-awesome-icon>
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </data-tables>
</template>


<script>
import CollectionCreateForm from "@/components/Forms/CollectionCreateForm"
import CollectionEditForm from "@/components/Forms/CollectionEditForm"
import CollectionSortForm from "@/components/Forms/CollectionSortForm"

import DateTime from "@/mixins/DateTime"

import { mapActions, mapGetters } from "vuex"

export default {
  name: "CollectionTable",

  mixins: [DateTime],

  data() {
    return {
      searchDef: {
        prop: "name",
        value: "",
      },

      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "order",
          order: "ascending",
        },
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      data: "collections/list",
    }),
  },

  methods: {
    ...mapActions({
      refreshCollections: "collections/refresh",
      removeCollection: "collections/remove",
    }),
    openCreateCollectionModal() {
      this.$modal.show(
        CollectionCreateForm,
        {},
        {
          name: "collection-create-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    openSortCollectionModal() {
      this.$modal.show(
        CollectionSortForm,
        {
          data: this.data,
        },
        {
          name: "collection-sort-form",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    collectionDropdown(command, collection) {
      if (command === "edit") {
        this.$modal.show(
          CollectionEditForm,
          {
            existing: collection,
          },
          {
            name: "collection-edit-form",
            adaptive: true,
            height: "auto",
            scrollable: true,
          }
        )
      } else if (command === "delete") {
        this.$confirm(
          "Delete " + collection.name + "? This action cannot be undone.",
          "",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "error",
            callback: (action) => {
              if (action === "confirm") {
                this.axios
                  .post("collections/delete/" + collection.slug, {})
                  .then((response) => {
                    this.removeCollection(response.data.data)
                    this.$message.success(response.data.message)
                  })
                  .catch((resp) => {
                    this.$root.globalRequestError(resp)
                  })
              }
            },
          }
        ).catch(() => {})
      }
    },
  },

  watch: {
    data() {
      this.loading = false
    },
  },

  created() {
    this.loading = true
    this.refreshCollections()
  },
}
</script>
