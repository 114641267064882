<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Reorder Collections</span>
      <el-button type="text" @click="$modal.hide('collection-sort-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>

    <el-alert
      title="Drag'n'drop Collections into your desired order and press Save to continue."
      type="info"
      style="margin-bottom: 0.5rem"
    ></el-alert>

    <div class="drag-container">
      <vue-draggable v-model="collections">
        <el-row
          v-for="(collection, key) in collections"
          :key="collection.slug"
          type="flex"
          class="collection-row"
        >
          <el-col style="flex: 0; min-width: 50px; font-weight: bold">
            {{ key + 1 }}
          </el-col>
          <el-col style="flex: 1">
            {{ collection.name }}
          </el-col>
        </el-row>
      </vue-draggable>
    </div>

    <el-button
      @click="submit"
      type="primary"
      plain
      medium
      :style="{ width: '100%' }"
      v-loading="loading"
    >
      Save
    </el-button>
  </el-card>
</template>

<style scoped>
.drag-container {
  box-shadow: 0 0 0 1px #c8cdd0;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.collection-row {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #fefefe;
  cursor: move;
}

.collection-row:nth-child(2n) {
  background-color: #fafafa;
}

.sortable-ghost {
  background-color: rgba(64, 158, 255, 0.3) !important;
}
</style>

<script>
import VueDraggable from "vuedraggable"
import { mapActions } from "vuex"

export default {
  name: "CollectionSortForm",

  components: {
    VueDraggable,
  },

  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      collections: [],
      loading: false,
    }
  },
  computed: {
    action() {
      return "collections/order/"
    },
  },

  methods: {
    ...mapActions({
      setCollections: "collections/set",
    }),
    submit() {
      let order = this.$_.map(this.collections, "id")
      this.loading = true

      this.axios
        .post(this.action, { order: order })
        .then((response) => {
          this.setCollections(response.data.data)
          this.$message.success(response.data.message)
          this.loading = false
          this.$modal.hide("collection-sort-form")
        })
        .catch((resp) => {
          this.loading = false
          this.$root.globalRequestError(resp)
        })
    },
  },

  created() {
    this.collections = this.$_.cloneDeep(this.data)
  },
}
</script>
