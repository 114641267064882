<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Create A Collection</span>
      <el-button type="text" @click="$modal.hide('collection-create-form')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="create"
      label-position="right"
      label-width="100px"
    >
      <el-form-item
        :error="form.errors.first('name') ? form.errors.first('name') : null"
        label="Name"
        required
      >
        <el-input
          v-model="collection.name"
          type="text"
          placeholder="Name"
          @focus="form.errors.clear('name')"
          v-input-focus
        ></el-input>
      </el-form-item>

      <el-form-item
        :error="
          form.errors.first('description')
            ? form.errors.first('description')
            : null
        "
        label="Description"
      >
        <el-input
          v-model="collection.description"
          type="textarea"
          placeholder="Description"
          :autosize="{ minRows: 2, maxRows: 4 }"
          @focus="form.errors.clear('description')"
        ></el-input>
      </el-form-item>

      <el-form-item label="Settings">
        <el-checkbox v-model="collection.fileable"> Files </el-checkbox>
        <el-checkbox v-model="collection.linkable"> Links </el-checkbox>
        <el-checkbox v-model="collection.orderable"> Orderable </el-checkbox>
        <el-checkbox v-model="collection.downloadable">
          Downloadable
        </el-checkbox>
      </el-form-item>
      <el-form-item
        :error="
          form.errors.first('mime_types')
            ? form.errors.first('mime_types')
            : null
        "
        label="File Types"
        v-if="collection.fileable"
      >
        <el-select
          v-model="collection.mime_types"
          :style="{ width: '100%' }"
          multiple
          clearable
          placeholder="Assign Allowed File Types..."
          @focus="form.errors.clear('mime_types')"
        >
          <el-option-group
            v-for="group in mimes"
            :key="group.group"
            :label="group.group"
          >
            <el-option
              v-for="item in group.mimes"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        :style="{ width: '100%' }"
        v-loading="loading"
      >
        Create
      </el-button>
    </el-form>
  </el-card>
</template>


<script>
import Form from "form-backend-validation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CollectionCreateForm",

  mixins: [],

  data() {
    return {
      collection: {
        name: "",
        description: "",
        fileable: true,
        linkable: false,
        orderable: true,
        downloadable: true,
        mime_types: [],
      },
      form: new Form(),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      mimes: "collections/mimes",
    }),
  },

  methods: {
    ...mapActions({
      addCollection: "collections/add",
    }),
    create() {
      this.loading = true

      this.axios
        .post("collections/create", this.collection)
        .then((response) => {
          this.addCollection(response.data.data)
          this.$modal.hide("collection-create-form")
          this.$message.success(response.data.message)
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },
}
</script>
