<template>
  <el-card>
    <collection-table></collection-table>
  </el-card>
</template>


<script>
import CollectionTable from "@/components/Tables/CollectionTable"

export default {
  name: "CollectionAdmin",

  components: {
    CollectionTable,
  },
}
</script>
